import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from './store/store';
import { notification } from 'antd';
import Error from './components/Error';
import AvailTermsOfUse from './components/AvailTermsOfUse';
import HomeLogo from './components/HomeLogo';
import Maintenance from './components/Maintenance';
import { loadTheme } from './theme';

const App = (): JSX.Element => {
  const error: string | null = useStore((state) => state.error);
  const clearError = useStore((state) => state.clearError);

  useEffect(() => {
    if (error) {
      openNotification();
    }
  }, [error]);

  useEffect(() => {
    loadTheme();
  }, []);

  const openNotification = () => {
    notification.error({
      message: 'Error (Please check your permissions)',
      description: error,
      duration: 30,
      onClose: () => clearError(),
    });
  };

  return (
    <Router>
      <Routes>
        <Route path="home/*" element={<HomeLogo />} />
        <Route path="error/*" element={<Error />} />
        <Route path="maintenance/*" element={<Maintenance />} />
        <Route path="avail-tou/*" element={<AvailTermsOfUse />} />
        <Route path="*" element={<Navigate to="maintenance" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
