import { Result, Typography, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useStore } from '../store/store';
const { Paragraph, Text } = Typography;

const Error = (): JSX.Element => {
  const error: string | null = useStore((state) => state.error);

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '8px',
      }}
    >
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please doublecheck that you have the right permissions."
        extra={[
          <div key="errorMsg" style={{ backgroundColor: '#F6F6F6', padding: '24px', marginBottom: '24px' }}>
            <Paragraph>
              <Text strong>The following error happened:</Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
              {error}
            </Paragraph>
          </div>,
          <Button key="reloadButton" onClick={() => window.location.reload()}>
            Reload page
          </Button>,
        ]}
      />
    </div>
  );
};

export default Error;
