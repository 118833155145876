const HomeLogo = (): JSX.Element => (
  <div
    style={{
      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img width="50%" src="/celus_Logo.svg" />
  </div>
);

export default HomeLogo;
