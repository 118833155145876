import { ConfigProvider } from 'antd';
import { DEFAULT_CELUS_THEME } from './default';
import { ICELUSThemeGroup } from './model';

export const loadTheme = (theme?: ICELUSThemeGroup): void => {
  const pagesTheme: ICELUSThemeGroup | undefined = theme || DEFAULT_CELUS_THEME;
  if (!pagesTheme) {
    return;
  }
  ConfigProvider.config({
    theme: {
      primaryColor: pagesTheme.palette?.accent,
      infoColor: pagesTheme.palette?.info,
      successColor: pagesTheme.palette?.success,
      processingColor: pagesTheme.palette?.info,
      errorColor: pagesTheme.palette?.error,
      warningColor: pagesTheme.palette?.warning,
    },
  });
};
