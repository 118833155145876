import { Button, Typography } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
const { Paragraph, Text, Title, Link } = Typography;

const AvailTermsOfUse = (): JSX.Element => {
  const headingSizes: Record<'main' | 'paragraph', 1 | 2 | 3 | 4 | 5> = {
    main: 2,
    paragraph: 4,
  };
  const siteLinks: string[] = [
    'https://www.avnet.com',
    'https://Avnet.us.Celus.io',
    'https://Avnet.asia.Celus.io',
    'https://abacus.celus.io/',
    'https://silica.celus.io/',
    'https://ebv.celus.io/',
  ];
  const supportEmails: string[] = [
    'OnlineSupportUS@avnet.com',
    'OnlineSupportEU@Avnet.com',
    'OnlineSupportAsia@avnet.com',
  ];

  const [isBottom, setIsBottom] = useState<boolean>(false);

  const linkToHref = (link: string): string => (link.includes('@') ? `mailto:${link}` : link);

  const links = (links: string[]): JSX.Element[] => {
    const firstLinks: string[] = [...links];
    const lastLink: string = firstLinks.pop() || '';
    return [
      ...firstLinks.map((l, i) => (
        <span key={`${i}`}>
          {' '}
          <Link href={linkToHref(l)} target="_blank">
            {l}
          </Link>
          ,
        </span>
      )),
      <span key="last">
        {' '}
        as well as{' '}
        <Link href={linkToHref(lastLink)} target="_blank">
          {lastLink}
        </Link>
      </span>,
    ];
  };

  const scroll = () => {
    const el = document.getElementById('availTOUContainer');
    if (el) {
      el.scrollBy(0, window.innerHeight - 100);
    }
  };

  const updateIsBottom = () => {
    const el = document.getElementById('availTOUContainer');
    if (el && el.scrollTop === el.scrollHeight - el.offsetHeight) {
      return setIsBottom(true);
    }
    return setIsBottom(false);
  };

  return (
    <div
      id="availTOUContainer"
      onScroll={() => updateIsBottom()}
      style={{
        backgroundColor: 'white',
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Button
        style={{
          position: 'absolute',
          bottom: '12px',
        }}
        type="default"
        shape="circle"
        icon={<DownCircleOutlined />}
        size="large"
        disabled={isBottom}
        onClick={() => scroll()}
      />
      <div
        style={{
          backgroundColor: 'white',
          padding: '48px 24px',
          width: 'min(100%, 1200px)',
          height: 'auto',
        }}
      >
        <Title level={headingSizes.main}>Terms of Use - AVAIL</Title>
        <Paragraph>
          <Text>
            Avnet, Inc., on behalf of its subsidiaries and affiliates worldwide, (collectively, “Avnet”), operates Sites
            (i.e., the web sites located at {links(siteLinks)} together and individually the “Site”) and related content
            of Avnet and its licensors. Avnet, through the Site, provides online access to information about products
            available from Avnet (the “Products”) for commercial purposes and enables You, the person representing a
            company (B2B) entering and using in any way the Site (“You”), to purchase Products online, manage Your
            orders and backlog, monitor the supply chain as well as design new products online (all of the aforesaid
            collectively referred to as the “Services”).
          </Text>
        </Paragraph>
        <Paragraph>
          <Text strong>
            By accessing and using the Site You agree to each of the terms and conditions set forth herein (“Terms of
            Use”).
          </Text>
          <Text>
            For certain functionality additional terms may apply. When You use the Services to purchase goods, this act
            will be subject to the guidelines, terms and agreements applicable to that purchase, e.g. the written
            agreement between Yourself and Avnet or the Avnet Terms and Conditions of Sale. If these Terms of Use are in
            any way inconsistent with the Avnet Terms and Conditions of Sale, the latter will prevail unless explicitly
            stated otherwise in these Terms of Use.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>1. Access to the Site</Title>
        <Paragraph>
          <Text>
            Registration is not required to access and use certain functionalities of the Site. However, in order to
            access the full functionality of the Services, such as purchasing goods and saving your design, Your
            company, which wishes to use the Services, will need to register for an Avnet account. Accounts are not
            provided to consumers and by creating or activating your account, You represent and warrant that You act on
            behalf of a company or are an independent business owner. To complete the respective registration, You need
            to identify Yourself as an authorized representative for Your company (“User”) for the purposes of use of
            the Services. Upon registering, You will have the exclusive right to access the Site and use the Services on
            behalf of Your company using Your log-in details provided by Avnet. You represent and warrant that You have
            the right to purchase goods and view and manage past orders. You are responsible for maintaining the
            confidentiality of your account to the Site and password, which is confidential, and for restricting access
            to the computers used to access the Services, and You agree to accept responsibility for all activities that
            occur under Your account or password. If You believe that the security or privacy of Your account, password
            or other personal information has been compromised, please contact Avnet immediately under
            {links(supportEmails)}
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>2. License</Title>
        <Paragraph>
          <Text>
            Subject to Your compliance with these Terms of Use, Avnet or its content providers grant You a limited,
            non-exclusive, non-transferable, non-assignable, revocable license to access and make commercial use of the
            Services and the Site for Your internal business purposes only. This license does not include any collection
            and use of any product listings, descriptions, images or prices; any derivative use of the Services, the
            Site, or its contents; any downloading or copying of account information for the benefit of another
            merchant; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not
            expressly granted to You in these Terms of Use or the Avnet Terms and Conditions of Sale are reserved and
            retained by Avnet or its licensors, suppliers, publishers, rights holders, or other content providers.
            Neither the Services nor any part thereof may be reproduced, duplicated, copied, sold, resold, visited, or
            otherwise exploited for any commercial purpose without express written consent of Avnet. You may not frame
            or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including
            images, text, page layout, or form) of Avnet its licensors, suppliers, publishers, rights holders, or other
            content providers without express written consent. You may not use any meta tags or any other “hidden text”
            utilizing Avnet’s name or trademarks without the express written consent of Avnet. You may not use the
            Services for any purposes not expressly granted herein and only as permitted by law. You may not provide the
            Services or Avnet Content to any third party or otherwise make use of the Site outside the limited scope of
            the license. You understand and agree that if You do not comply with these Terms of Use, Avnet reserves the
            right to, at its sole discretion, terminate Your access to the Site or certain functionalities thereof, as
            well as to pursue any rights and remedies under law. Condition precedent for Your continued use of the
            Services is that You refrain from any action that might compromise the accessibility or security or
            integrity of the Site, the Content, or might otherwise violate the rights of Avnet, other Users or third
            parties. You engage to hold confidential any Confidential Information that you receive from Avnet.
            Confidential Information shall include, but not be limited to, trade secrets or any Avnet owned intellectual
            property, which is clearly identified by stamp or legend as being proprietary or confidential by Avnet.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>3. User-provided Content</Title>
        <Paragraph>
          <Text>
            If and as You provide or create by using the Services of the Site any information or materials, by upload or
            any other means, to the Site (“User-provided Content”), You represent and warrant to Avnet that You have the
            right to do so and that such User-provided Content will not violate any law or the rights of any third
            party. If such User-provided Content is made available by You to Avnet, You grant Avnet a non-exclusive,
            royalty-free, unlimited worldwide, irrevocable and perpetual license to host, store, display, distribute,
            use, User-provided Content as intended, in any manner or media, as whole or in part. This license subsists
            even if You discontinue Your use of the Services. You guarantee that You have the right to grant such
            license to Avnet. Avnet reserves the right to delete or otherwise remove User-provided Content at its sole
            discretion, e.g. if it is deemed inappropriate or inflammatory. If such User-provided Content is received,
            You acknowledge that (a) it will not be considered confidential or proprietary, (b) Avnet is under no
            obligation to keep such information confidential.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>You may work and store on a maximum amount of twenty designs or data blocks at any given time.</Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>4. Use of Reference Designs / Use of the Services</Title>
        <Paragraph>
          <Text>
            Avnet may provide You with examples of reference designs free of charge to use for generating Your own
            designs. These are based on existing working boards or development kits and are offered “as is” with no
            guarantee as to their completeness nor function. In addition, the results produced by the Services are
            machine-generated and require verification and validation by a qualified expert on an individual basis.
            Avnet does not warrant that the results generated by the Services are error-free or usable.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>The designs can be either saved on the Services or exported and saved on Your hardware platform.</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Avnet acknowledges that the User-provided Content created by You will not be used, recreated and repurposed
            in any way by Avnet without Your consent. They remain Your intellectual property or the intellectual
            property of the company on whose behalf you registered on the Site as the User. Avnet has no intention of
            using, repurposing, selling or distributing the user data as well as User Created Content that You uploaded
            or created on the Site. Information in relation to them are purely collected as a business tool to aid users
            and companies to accelerate their design process.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            No personal data will be tracked in this process, but interaction with the site and the AVAIL tool will be
            tracked via cookies to provide business relevant data. Please refer for more detailed information to our
            cookies policy. Business data collected with this tool maybe shared indirectly with Avnet’s 3rd parties for
            business reasons. Business data may include for example:
          </Text>
          <ul>
            <li>
              <Text>Popular parts or devices used, as an average.</Text>
            </li>
            <li>
              <Text>Parts or devices which are commonly used in a design with other parts or devices.</Text>
            </li>
            <li>
              <Text>Popular Manufacturers for a certain part, devices and/or technologies.</Text>
            </li>
            <li>
              <Text>Data for number of new designs started and completed in the tool.</Text>
            </li>
            <li>
              <Text>Data for new designs started and completed based on time taken for this process.</Text>
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          <Text>
            Should You wish to share one or more of Your designs with members of Avnet, You need to either send the
            design or share the design physically or electronically. Certain data and related technology are subject to
            export control regulations of the United States, the European Union, and/or other countries, excluding
            boycott laws (&quot;Export Laws&quot;). You shall comply with such Export Laws and obtain any license or
            permit required to transfer, export, re-export or import such data and related technology. You shall not
            export or re-export such data and related technology to any country or entity to which such export or
            re-export is prohibited, including any country or entity under sanction or embargoes administered by the
            United States, European Union or other countries. You shall not use the data and related technology in
            relation to chemical, biological or nuclear weapons, rocket systems (including ballistic missile systems,
            space launch vehicles and sounding rockets) or unmanned air vehicles capable of delivering same, or in the
            development of any weapons of mass destruction.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>5. Privacy</Title>
        <Paragraph>
          <Text>
            Please review our Privacy Notice and Cookie Statement which also governs Your use of the Services to
            understand how we use and ensure the security of Your personal information, if and as You provide us
            therewith.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>6. Electronic Communications</Title>
        <Paragraph>
          <Text>
            When You use the Services, or send e-mails to us, You are communicating with us electronically. You consent
            to receive communications from us electronically for operational purposes only, if and when appropriate.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>7. Copyright</Title>
        <Paragraph>
          <Text>
            All information that You perceive, except for Your designs, be it visually, acoustically or otherwise,
            included in or made available through the Services, such as text, graphics, logos, button icons, images,
            audio clips, digital downloads, videos and data compilations (the “Content”) is the property of Avnet or its
            Content suppliers and protected by United States or respective other applicable copyright laws. All
            information referencing Avnet propriety design content, calculators and AVAIL tool contained in the Design
            Hub portal is ultimately the sole property of Avnet. Inc. No intellectual property rights are granted by the
            access or use of the Services or the disclosure of its content. Such data shall not be reproduced or
            disclosed to a third party without the express written consent of Avnet. Such data shall not be used for any
            purpose other than that, for which access is granted to this portal to the user.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>8. Trademarks</Title>
        <Paragraph>
          <Text>
            Graphics, logos, page headers, button icons, scripts, and service names included in or made available
            through the Services are trademarks or trade dress of Avnet in the U.S. and other countries. Avnet’s
            trademarks and trade dress may not be used in connection with any product or service that is not Avnet’s, in
            any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits
            Avnet. All other trademarks not owned by Avnet that appear in the Services are the property of their
            respective owners, who may or may not be affiliated with, connected to, or sponsored by Avnet.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>9. Patents</Title>
        <Paragraph>
          <Text>
            One or more patents owned by Avnet may apply to the Services and to the features and services accessible via
            the Services. Portions of the Services may operate under license of one or more patents.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>10. Copyright complaints</Title>
        <Paragraph>
          <Text>
            Avnet respects the Intellectual Property, patents and trademarks including all similar rights of others. If
            You are concerned that Your work has been copied in a way that constitutes a copyright infringement, please
            follow our Copyright Notice and Claims Policy procedure as outlined below.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>11. DISCLAIMER OF WARRANTIES</Title>
        <Paragraph>
          <Text>
            THE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES ARE PROVIDED BY AVNET ON AN “AS IS” AND
            “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. AVNET MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICES, OR THE INFORMATION, CONTENT, MATERIALS,
            PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
            SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT
            YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, AVNET DISCLAIMS ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. AVNET DOES NOT WARRANT THAT THE AVNET SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS
            (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE AVNET
            SERVICES, AVNET’S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM AVNET ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. CERTAIN LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
            CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
            LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>12. Additional Disclaimers</Title>
        <Paragraph>
          <Text>
            Notwithstanding and without contradiction or limitation to the foregoing disclaimer of warranties:
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Site availability, correction of errors and inaccuracies: Avnet does not warrant that the Site, and/or some
            of its functionalities performed by the Site or the Services will be available to You at all times, or that
            such functionalities will be uninterrupted, timely, secure, error-free and that all information including,
            without limitation, prices and delivery periods are correct and free of errors, typologically or otherwise,
            or that defects, errors or inaccuracies in the Site or the Services will be corrected. Avnet does not
            warrant the accuracy or completeness of the content, or that any errors in the Content will be corrected.
            The Content may contain typographical errors or other errors or inaccuracies and may not be complete or
            current. Avnet, however, reserves the right to correct any errors, inaccuracies or omissions and to change
            or update the Content at any time without prior notice.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Site harmlessness: Avnet disclaims all warranties that the Services, i.e. the Site and its Content, Avnet´s
            servers and electronic communication will be free of viruses or other harmful software or otherwise
            non-detrimental to Your hardware or software.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Geographic limitations: Like most internet web sites, this Site is accessible worldwide. However, not all
            Products or services offered by Avnet are available to all persons or in all geographic locations. Avnet
            reserves the right to limit the provision of its Products and services to any person, geographic area, or
            jurisdiction and to limit the quantities of any Products or services that it provides. You agree to comply
            with all applicable laws and local rules regarding the transmission of technical data, acceptable content,
            and online conduct.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Third party links: Hypertext links to third party web sites or information do not constitute or imply an
            endorsement, sponsorship, or recommendation by Avnet of the third party, the third party web site, or the
            information contained therein, unless expressly stated on the Site. You acknowledge and agree that Avnet is
            not responsible for the availability of any such third party web sites and that Avnet does not endorse or
            warrant, and is not responsible or liable for, any such web site or the content thereon. You are solely
            responsible for making Your own decisions regarding Your interactions or communications with any other web
            site.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Infringement Disclaimer: The Content may be subject to patent, trademark, copyright, design and other rights
            of third parties. AVNET SHALL, OTHER THAN TAKING DOWN ALLEGEDLY INFRINGING THIRD PARTY MATERIAL STORED ON
            THE SITE IN ACCORDANCE WITH AVNET´S COPYRIGHT NOTICE AND CLAIMS POLICY AS OUTLINED BELOW, NOT BE RESPONSIBLE
            OR LIABLE TO YOU BASED UPON ANY CLAIM OF INFRINGEMENT OF ANY SUCH RIGHTS. Nothing herein shall be construed
            to grant any rights or license to use any software or other intellectual property in any manner or for any
            purpose not expressly permitted by such license agreement.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>13. LIMITATION OF LIABILITY</Title>
        <Paragraph>
          <Text>
            AVNET WILL IN NO EVENT, EXCEPT FOR ITS OWN MALICIOUS INTENT, OTHERWISE WILLFUL MISCONDUCT OR GROSSLY
            NEGLIGENT BEHAVIOR, BE LIABLE FOR ANY DAMAGES INCLUDING, BUT NOT LIMITED TO, INDIRECT, INCIDENTAL, SPECIAL,
            PUNITIVE, EXEMPLARY AND CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS, LOST SAVINGS, LOSS
            OR THEFT OF DATA OR LOSS OF BUSINESS OPPORTUNITY) ARISING FROM OR RELATING TO THE USE OF OR THE INABILITY TO
            USE THE SERVICES, INCLUDING WITHOUT LIMITATION, (I) THE USE OF OR INABILITY TO USE THE SITE, ANY OF ITS
            FUNCTIONALITIES OR ITS CONTENT; (II) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS OR OTHER INACCURACIES IN
            THE SITE, ANY OF ITS FUNCTIONALITIES OR ITS CONTENT; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; OR (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE. THIS LIMITATION OF
            LIABILITY OCCURS EVEN IF AVNET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUBJECT TO THE
            FOREGOING, IN CASE OF A VIOLATION OF A CORE CONTRACTUAL OBLIGATION, AVNET´S LIABILITY SHALL NOT EXCEED THE
            DAMAGES TYPICALLY AND FORESEEABLY OCCURRED IN THE RESPECTIVE INDIVIDUAL SITUATION.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            NOTWITHSTANDING ANY OF THE FOREGOING PROVISIONS, IN NO EVENT SHALL THE LIABILITY OF AVNET RELATING IN ANY
            WAY TO THE SERVICES EXCEED THE AMOUNT OF USD $50,000, REGARDLESS OF THE LEGAL THEORY ASSERTED FOR SUCH
            LIABILITY, WHETHER IN CONTRACT, TORT, WARRANTY OR OTHERWISE.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            IF YOU ARE DISSATISFIED WITH THE SERVICES INCLUDING THE SITE, ITS FUNCTIONALITIES AND ITS CONTENT, OR WITH
            THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY SHALL BE TO DISCONTINUE USING THE SERVICES.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Except as required by law, any claim with respect to the Services must be brought against Avnet within one
            (1) year from the date the cause of action has arisen.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>14. Indemnity</Title>
        <Paragraph>
          <Text>
            You understand and agree that You are personally responsible for Your behavior on the Site. You agree to
            indemnify, defend and hold harmless Avnet, its joint ventures, business partners, licensors, employees,
            agents, and any third-party information providers to the Service from and against all claims, losses,
            expenses, damages and costs (including, but not limited to, direct, incidental, special, consequential,
            punitive, exemplary and indirect damages), and reasonable attorneys’ fees, made by any third party due to or
            arising out of Your use or misuse of, or inability to use, the Service including the Site and its Content,
            including also Your use of the Site to provide a link to another site or to upload content or other
            information to the Site, Your reliance upon any information or recommendation provided by Customer Care
            personnel, or any violation by You of these Terms of Use.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>15. General Provisions</Title>
        <Paragraph>
          <Text>
            These Terms of Use constitute the entire agreement between You and Avnet as regards Your use of the
            Services. In case any of the provisions herein is deemed invalid or unenforceable by a court of competent
            jurisdiction, the remainder of provisions will not be affected thereby and will remain in full force and
            effect. These Terms of Use shall be governed by and construed according to the laws of the state of New York
            (without regard to its conflict of law provisions). For purposes of litigating any dispute that arises under
            these Terms of Use, Avnet and You hereby submit to and consent to the jurisdiction of the State of Arizona,
            and agree that such litigation will be conducted in the courts of Maricopa County, Arizona, or the federal
            courts for the United States for the District of Arizona.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>16. Changes to these Terms of Use</Title>
        <Paragraph>
          <Text>
            Avnet reserves the right to change these Terms of Use at any time without prior notice to You. In the event
            of a change, Avnet will notify You thereof via e-mail to the e-mail-address stated in Your account.
          </Text>
        </Paragraph>
        <Title level={headingSizes.paragraph}>17. Copyright Notice and Claims Policy</Title>
        <Paragraph>
          <Text>
            Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement
            should be sent to Avnet´s Designated Agent (the “Copyright Agent”).
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Note: the following information is provided for purposes of notifying Avnet of a possible infringement of
            Your copyrighted material. Please refrain from using this information to contact our Copyright Agent for
            other purposes, such as technical assistance, piracy claims etc. Such requests cannot and will not be
            replied to through this process.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>Notices of copyright infringement should be sent in written form to our Copyright Agent:</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Copyright Agent Avnet, Inc. 2211 S. 47th Street Phoenix, AZ 85034 Telephone: +1 480-643-2000 Fax: +1
            480-794-9782 Email: copyrightagent@avnet.com
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>The notification should include the following information:</Text>
          <Text>
            (i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed (“Complaining Party”).
          </Text>
          <Text>
            (ii) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
            works at a single online site are covered by a single notification, a representative list of such works at
            that site.
          </Text>
          <Text>
            (iii) Identification of the material that is claimed to be infringing or to be the subject of infringing
            activity and that is to be removed or access to which is to be disabled, and information reasonably
            sufficient to permit the service provider to locate the material. (iv) Information reasonably sufficient to
            permit the service provider to contact the complaining party, such as an address, telephone number, and, if
            available, an electronic mail address at which the complaining party may be contacted. (v) A statement that
            the Complaining Party has a good faith belief that use of the material in the contested manner is not
            authorized by the copyright owner, its agent, or the law. (vi) A statement that the information in the
            notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on
            behalf of the owner of an exclusive right that is allegedly infringed.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Upon receipt of the written notification containing the information as outlined in (i) through (vi) above,
            Avnet shall:
          </Text>
          <Text>(i) remove or disable access to the material that is alleged to be infringing;</Text>
          <Text>(ii) forward the written notification to such alleged infringer (“Subscriber”);</Text>
          <Text>
            (iii) take reasonable steps to promptly notify the Subscriber that it has removed or disabled access to the
            material.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            To be effective, a Counter Notification must be a written communication provided to the Avnet´s Designated
            Agent that includes substantially the following:
          </Text>
          <Text>(i) A physical or electronic signature of the Subscriber;</Text>
          <Text>
            (ii) Identification of the material that has been removed or to which access has been disabled and the
            location at which the material appeared before it was removed or access to it was disabled;
          </Text>
          <Text>
            (iii) A statement under penalty of perjury that the Subscriber has a good faith belief that the material was
            removed or disabled as a result of mistake or misidentification of the material to be removed or disabled;
          </Text>
          <Text>
            (iv) The Subscriber’s name, address, and telephone number, and a statement that the Subscriber consents to
            the jurisdiction of Federal District Court for the judicial district in which the address is located, or if
            the Subscriber’s address is outside of the United States, for any judicial district in which the Avnet may
            be found, and that the Subscriber will accept service of process from the person who provided notification
            or an agent of such person.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Upon receipt of a Counter Notification containing the information as outlined in 1 through 4 above, Avnet
            shall:
          </Text>
          <Text>(i) promptly provide the Complaining Party with a copy of the Counter Notification;</Text>
          <Text>
            (ii) inform the Complaining Party that it will replace the removed material or cease disabling access to it
            within ten (10) business days;
          </Text>
          <Text>
            (iii) replace the removed material or cease disabling access to the material within ten (10) to fourteen
            (14) business days following receipt of the Counter Notification, provided Avnet’s Designated Agent has not
            received notice from the Complaining Party that an action has been filed seeking a court order to restrain
            Subscriber from engaging in infringing activity relating to the material on Avnet’s network or system.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text strong>Printing: You are encouraged to print a copy of these Terms of Use for Your records.</Text>
        </Paragraph>
      </div>
    </div>
  );
};

export default AvailTermsOfUse;
