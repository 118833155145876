export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const openInSameTab = (url: string): void => {
  window.location.assign(url);
};

export const reloadWindow = (): void => {
  return document.location.reload();
};
