import { GetState, SetState } from 'zustand';
import { State } from './store';
import { IStatusActiveMaintenance } from './model';

const BaseGet = async <T>(url: string): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fetch(url)
      .then((res: Response) => {
        if (res.status > 299) {
          return reject();
        }
        return res.json().then(resolve).catch(reject);
      })
      .catch(reject);
  });
};

export const statusGetActiveMaintentance = async (
  set: SetState<State>,
  _get: GetState<State>,
): Promise<IStatusActiveMaintenance> => {
  return new Promise<IStatusActiveMaintenance>((resolve, reject) => {
    BaseGet<IStatusActiveMaintenance>('https://status.celus.io/api/v2/scheduled-maintenances/active.json')
      .then((activeMaintenance: IStatusActiveMaintenance) => {
        set({ activeMaintenance });
        resolve(activeMaintenance);
      })
      .catch(reject);
  });
};
