import create, { GetState, SetState } from 'zustand';
import { DEFAULTS } from './defaults';
import { IStatusActiveMaintenance } from './model';
import { statusGetActiveMaintentance } from './statusApiActions';

export interface State {
  error: string | null;
  activeMaintenance?: IStatusActiveMaintenance;
}

const _DEFAULTS = DEFAULTS();

export const useStore = create((set: SetState<State>, get: GetState<State>) => ({
  error: _DEFAULTS.error,
  activeMaintenance: undefined,

  // UX
  clearError: () => {
    set(() => ({ error: null }));
  },

  // Statuspage API
  statusGetActiveMaintentance: () => statusGetActiveMaintentance(set, get),
}));
