import { ICELUSThemeGroup } from './model';

export const DEFAULT_CELUS_THEME: ICELUSThemeGroup = {
  palette: {
    accent: '#004c97',
    accentHover: ' #33a0c1',
    accentLightBg: '#99d0e0',
    text: '#172335',
    textLink: '#004C97',
    dark: '#000000',
    pageBg: 'white',
    canvasBg: '#f2f2f2',
    accentBg: '#616260',
    hoverGray: '#0000000a',
    block: '#161A25',
    whiteText: '#FFFFFF',
    lightText: '#E3E3E5',
    darkText: '#413E46',
    accentDark: '#002850',
    dashboardBg: '#FBFAFA',
    activeOption: '#172335',
    disabled: '#B2B2B2',
    border: '#D7D7D7',
    imageBg: '#EFEFEF',
    white: '#FFFFFF',
    settingsActive: 'white',
    settingsNotActive: 'lightgray',
    backdropBg: '#B1B1B180',
    loaderBg: '#E0E0E0',
    success: '#009954',
    active: '#d9fde6',
    error: '#c62500',
    lightError: '#fcc',
    warning: '#efb800',
    warningBackground: '#fff0cd',
    inactive: '#B1B1B1',
    separator: '#D9D9D9',
    lightSeparator: '#0000001f',
    info: '#5D5D5D',
    thumb: '#707070',
    linkHover: '#C5C5C5',
    schematics: '#932020',
    layout: '#22761B',
    bom: '#2866A3',
    pdf: '#5A0000',
    change: 'rgb(224, 145, 66,0.12)',
  },
  sizes: {
    typoLogo: '40px',
    logo: '36px',
  },
  border: {
    thumbnail: '1px solid #707070;',
    lighter: '1px solid #D7D7D7;',
  },
  spacing: {
    extraSmall: 4,
    mediumSmall: 6,
    small: 8,
    normal: 16,
    screenContent: 12,
    medium: 24,
    large: 32,
    form: 12,
  },
  shadow: {
    tiny: '0px 1px 2px #0000001E',
    small: '0px 2px 4px #0000001E',
    normal: '0px 3px 6px #0000001E',
    topTiny: '0px -1px 2px #0000001E',
    prominent: '0px 1px 12px #00000029',
  },
  fonts: {
    normal: "'Roboto', sans-serif",
    semiBold: "'Roboto', semi-bold",
  },
  fontSize: {
    nether: '8px',
    quantum: '10px',
    nano: '12px',
    tiny: '13px',
    small: '14px',
    impact: '15px',
    normal: '16px',
    medium: '18px',
    mLarge: '20px',
    large: '24px',
    title: '32px',
  },
};
