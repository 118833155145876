import { Button, Progress, Space, Typography } from 'antd';
import { SyncOutlined, SelectOutlined, ReloadOutlined } from '@ant-design/icons';
import { openInNewTab, openInSameTab } from '../utils/navigation';
import { CSSProperties, useEffect, useState } from 'react';
import { useStore } from '../store/store';
import { IStatusActiveMaintenance, IStatusScheduledMaintenance } from '../store/model';

const { Title, Paragraph } = Typography;

const Maintenance = (): JSX.Element => {
  const margin = '12px';
  const width = 'max(25%, 320px)';
  const buttonStyle: CSSProperties = {
    margin: '6px',
    width: '138px',
  };

  const activeMaintenance = useStore((state) => state.activeMaintenance) as IStatusActiveMaintenance | undefined;
  const statusGetActiveMaintentance = useStore((state) => state.statusGetActiveMaintentance);

  const [now, setNow] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      openInSameTab('/');
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    statusGetActiveMaintentance();
    const interval = setInterval(() => {
      statusGetActiveMaintentance();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setNow(Date.now());
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    parseStatus();
  }, [now, activeMaintenance]);

  const parseStatus = async () => {
    if (
      activeMaintenance &&
      activeMaintenance.scheduled_maintenances &&
      activeMaintenance.scheduled_maintenances.length
    ) {
      const maintenance: IStatusScheduledMaintenance = activeMaintenance.scheduled_maintenances[0];
      const now: number = Date.now();
      const scheduled_for: number = new Date(maintenance.scheduled_for).getTime();
      const scheduled_until: number = new Date(maintenance.scheduled_until).getTime();
      const delta: number = scheduled_until - scheduled_for;
      const elapsed: number = now - scheduled_for;

      const progress: number = Math.round((elapsed / delta) * 100);
      setProgress(progress);
    }
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="/celus_Logo.svg" style={{ margin, width }} />
      <SyncOutlined spin style={{ fontSize: '75px', margin: '24px', color: '#004c97' }} />

      <Title level={2}>Platform Upgrades</Title>
      <Paragraph style={{ margin, width, textAlign: 'center' }}>
        <Paragraph style={{ margin: '0' }}>We are currently upgrading to deliver new features.</Paragraph>
        <Paragraph style={{ margin: '0' }}>Service will resume shortly.</Paragraph>
      </Paragraph>
      <Space
        style={{
          display: 'flex',
          margin,
        }}
      >
        <Progress type="circle" percent={progress} />
      </Space>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin,
          width,
        }}
      >
        <Button
          icon={<SelectOutlined style={{ transform: 'scale(-1, 1)' }} />}
          key="goToStatuspage"
          style={buttonStyle}
          onClick={() => openInNewTab('https://status.celus.io')}
        >
          Status Details
        </Button>
        <Button icon={<ReloadOutlined />} key="reloadButton" style={buttonStyle} onClick={() => openInSameTab('/')}>
          Reload page
        </Button>
      </Space>
    </div>
  );
};

export default Maintenance;
